$(document).ready(function(){
  drawEventItems()
})

function drawEventItems(){
  var appendItems = '';
  actionWebApiRestrict('/v1/stream/events', {}, 'GET').done(function(json) {
    if (json.status == 600) {
      let dataItems = json.data;
      $.each(dataItems, function(index, value){
        if(index < 5){
          let setNewTab = "";
          if(value.new_tab === true){setNewTab = 'target="_blank"'}
          appendItems += '<div class="home-event-list-detail">'
          appendItems += '<a href="'+value.link+'" '+setNewTab+' class="home-event-desc" onclick="panelTrackingPushSection(\'Kegiatan\',\''+value.link+'\',\'Bawah\',\''+value.link+'\')">'
          appendItems += '<img src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=" data-original="'+value.image_url+'.small" alt="cover event" class="lazy-event">'
          appendItems += '</a>'
          appendItems += '</div>'
        }
      })
      $('#home-event').html(appendItems)
      $('.variable-width').slick({
        speed: 300,
        slidesToShow: 1,
        infinite: false,
        variableWidth: true
      });
      $('.slick-prev').html("")
      $('.slick-next').html("")
      $('.slick-dots button').html("")
      $('.lazy-event').lazyload({effect: "fadeIn"})
      $('.variable-width').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.lazy-event').trigger("appear")
      });
    }
  })
  
}

var panelTrackingPushSection = function(item, target, position, title) {
  dataLayer.push({
    'event': 'paneltracking',
    'panelname': item,
    'panelposition': position,
    'panelpage_url': target,
    'panelpage_title': title
  });
}